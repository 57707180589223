@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.code-box-shape{
  position:absolute;
  top:-50px;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  width:50px;
  height:50px;
  border-radius:4px;
  background:#1890ff;
  z-index:1;
}

.nameDiv{
  margin:auto;
    /* height: 300px; */
    width: 400px;
    position: relative;;
    /* top:31.5vh; */
    bottom:0;
    left:0;
    right:0;
  font-size: 48px;
  
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.name{
  /* margin:auto; */
    /* height: 200px;
    width: 400px;
    position: relative;
    top:112px;
    bottom:0;
    left:0;
    right:0; */
    font-size: 48px;
    color: white;
    z-index: 2;
}
@media only screen and (max-width: 600px) {
  /* .name{
    margin:auto;
      height: 200px;
      width: 400px;
      position: relative;
      top:112px;
      bottom:0;
      left:15px;
      right:0;
      color: white;
  } */
  .name{
    font-size: 42px;
  }
  .code-box-shape{
    /* left: -8px !important; */
    
  }
  .tagline{
    color: white;
    font-size: large !important;
    /* position: relative; */
    /* left: 12% !important; */
    top:-5%;
    left: 0% !important;
    width: 300px;
  } 
  .site-card-wrapper-mobile{
    padding-left: 0rem !important;
  }
  .description p{
    color: white;
    font-size: small !important;
    width: 90vw !important;
    font-family: 'IBM Plex Sans', sans-serif;
  }
  .description{
    position: relative;
  }
}
.white{
  color: white;
  font-size: larger;
}
.tagline{
  color: white;
  font-size: x-large;
  position: relative;
  left: -5%;
  top:-5%;
}

.site-statistic-demo-card {
  background: #ececec;
  padding: 30px;
}

.description{
  background-color: #7510F7;
  height: 50vh;
}
.description h3{
  color: white;
  font-size: 30px;
}
.description p{
  color: white;
  font-size: 20px;
  width: 60vw;
  font-family: 'IBM Plex Sans', sans-serif;
}

.expDiv{
  background-color: #d2e0bf;
  /* background-color: #414e69; */
  /* height: 50vh; */

}

.is-current{
  background-color: #1890ff;
}
.myMenu li{
  float: left;
  margin: 0 0.5rem 0 0.5rem;
  padding: 0 1rem 0 1rem;
 
}

.myMenu li a{
  color: white;
}
